import React from "react"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <React.Fragment>
    <SEO title="404: Not found" />
    <h1>404: Не найдено</h1>
    <p>Тут ничего нет. Извините!</p>
  </React.Fragment>
)

export default NotFoundPage
